import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import getString from '../utils/get-string';

const RegisterAffirmation = ({strings}) => (
  <Layout>
    <Seo
      title={getString(strings, "11248417")}
      description={getString(strings, "11248419")}
    />

    <Page type="success">
      <Header closeUrl="/lessons">{getString(strings, "11220916")}</Header>
      <Content center={true}>
        <h1 className="h2 growing text-transform-uppercase">
          {getString(strings, "11248417")}
        </h1>
        <div className="page-text-container">
          <p className="h6">
            {getString(strings, "11248419")}
          </p>
        </div>
      </Content>
      <Footer>
        <FooterLinkNext to="/login">{getString(strings, "11220911")}</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)



export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11248417", "11220916", "11248419", "11220911"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <RegisterAffirmation {...data} {...props} />}
  />
)
